export default [
    {
        value: 5,
        title: 'success.review.1.title',
        subtitle: 'success.review.1.subtitle',
        content: 'success.review.1.content',
    },
    {
        value: 5,
        title: 'success.review.2.title',
        subtitle: 'success.review.2.subtitle',
        content: 'success.review.2.content',
    },
    {
        value: 5,
        title: 'success.review.3.title',
        subtitle: 'success.review.3.subtitle',
        content: 'success.review.3.content',
    },
]
