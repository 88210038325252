/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useRef } from 'react'
import ReactCarousel from 'react-multi-carousel'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Icon } from '@gymondo/frontend-core/components'
import arrowCircle from '@gymondo/frontend-core/svg/arrow-circle.svg'
import * as styleVars from '@gymondo/frontend-core/style-vars'
import 'react-multi-carousel/lib/styles.css'
import Slide from '../slide'

import style from './carousel.module.scss'

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: parseInt(styleVars.lgMin, 10) },
        items: 1,
    },
    tablet: {
        breakpoint: {
            max: parseInt(styleVars.lgMin, 10),
            min: parseInt(styleVars.xsMin, 10),
        },
        items: 1,
    },
    mobile: {
        breakpoint: { max: parseInt(styleVars.xsMin, 10), min: 0 },
        items: 1,
    },
}

// eslint-disable-next-line react/prop-types
export const customArrow = className => ({ onClick }) => (
    <div onClick={onClick} className={cn(style.navArrowWrapper, className)}>
        <Icon data={arrowCircle} className={style.navArrowIcon} />
    </div>
)

const ArrowLeft = customArrow(style.navArrowLeft)
const ArrowRight = customArrow(style.navArrowRight)

export const Carousel = ({ slidesConfig }) => {
    const ref = useRef()
    const [firstClientX, setFirstClientX] = useState()
    const [firstClientY, setFirstClientY] = useState()
    const [clientXDelta, setClientXDelta] = useState()
    const [clientYDelta, setClientYDelta] = useState()

    useEffect(() => {
        const touchStart = e => {
            setFirstClientX(e.touches[0].clientX)
            setFirstClientY(e.touches[0].clientY)
        }

        const preventTouch = e => {
            const xThreshold = 5
            const yThreshold = 10
            setClientXDelta(e.touches[0].clientX - firstClientX)
            setClientYDelta(e.touches[0].clientY - firstClientY)

            if (
                Math.abs(clientXDelta) > xThreshold &&
                Math.abs(clientYDelta) < yThreshold
            ) {
                e.preventDefault()
                e.returnValue = false

                return false
            }

            return true
        }

        ref.current.addEventListener('touchstart', touchStart)
        ref.current.addEventListener('touchmove', preventTouch, {
            passive: false,
        })

        return () => {
            ref.current.removeEventListener('touchstart', touchStart)
            ref.current.removeEventListener('touchmove', preventTouch, {
                passive: false,
            })
        }
    }, [clientXDelta, clientYDelta, firstClientX, firstClientY, ref])

    return (
        <div ref={ref}>
            <ReactCarousel
                swipeable
                showDots
                infinite
                responsive={responsive}
                ssr
                transitionDuration={500}
                containerClass={style.carouselWrapper}
                itemClass={style.item}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                dotListClass={style.dotList}
                customLeftArrow={<ArrowLeft />}
                customRightArrow={<ArrowRight />}
            >
                {slidesConfig.map(slide => (
                    <Slide key={slide.title} {...slide} />
                ))}
            </ReactCarousel>
        </div>
    )
}

Carousel.propTypes = {
    slidesConfig: PropTypes.array.isRequired,
}

export default Carousel
