import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Button } from '@gymondo/frontend-core/components'
import { injectIntl, FormattedMessage } from 'react-intl'
import { BackgroundImage } from '../../../../../component'

import style from './slide.module.scss'

export const Slide = ({
    intl,
    title,
    subtitle,
    text,
    cta,
    img,
    link,
    className,
}) => (
    <div className={cn(style.wrapper, className)}>
        <div className={style.imageWrapper}>
            <BackgroundImage fluid={img} />
        </div>
        <div>
            <h4 className={style.title}>
                <FormattedMessage id={title} />
            </h4>
            <div className={style.subtitle}>
                <FormattedMessage id={subtitle} />
            </div>
            <div className={style.content}>
                <FormattedMessage id={text} />
            </div>
            <a
                href={intl.formatMessage({ id: link[0].href })}
                className={style.cta}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Button size="medium">
                    <FormattedMessage id={cta} />
                </Button>
            </a>
        </div>
    </div>
)

Slide.propTypes = {
    intl: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    link: PropTypes.array.isRequired,
    className: PropTypes.string,
    img: PropTypes.object.isRequired,
}

Slide.defaultProps = {
    className: null,
}

export default injectIntl(Slide)
