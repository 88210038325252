import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import {
    Button,
    FluidWrapper,
    renderReviews,
} from '@gymondo/frontend-core/components'
import { mediaXsOnly } from '@gymondo/frontend-core/style-vars'
import { useMediaQuery } from '@gymondo/frontend-core/hooks'
import * as config from './config/success.config'
import reviews from '../../../service/review/review.constant'

import style from './success.module.scss'

import {
    BackgroundImage,
    ContentGrid,
    Cta,
    PageHero,
    SocialCard,
} from '../../../component'
import Carousel from './component/carousel'
import { setConfig } from '../../../service/layout'

const generateOriginalNameImageMap = imgs =>
    imgs.reduce((acc, img) => {
        acc[img.node.childImageSharp.fluid.originalName] =
            img.node.childImageSharp.fluid

        return acc
    }, {})

const renderHero = () => (
    <PageHero
        title={['success.hero.title.1', 'success.hero.title.2']}
        subtitle="success.hero.subtitle"
        className={style.heroWrapper}
    />
)

const renderFooter = img => (
    <FluidWrapper className={cn(style.heroContent, style.footerContent)}>
        <BackgroundImage fluid={img} imgClassName="image-position__65" overlay>
            <div className={style.footerTextWrapper}>
                <h2 className={cn(style.footerTitle, 'gym-h2')}>
                    <FormattedMessage
                        id="success.footer.title"
                        values={{
                            wrapper: (...msg) => (
                                <span className={style.primaryColor}>
                                    {msg}
                                </span>
                            ),
                        }}
                    />
                </h2>
                <div className={style.footerSubtitle}>
                    <FormattedMessage id="success.footer.subtitle" />
                </div>
                <Cta displayXS />
            </div>
        </BackgroundImage>
    </FluidWrapper>
)

const renderPageSection = (title, content) => (
    <div className={cn('wrapper', style.reviewSection)}>
        <h4 className={style.reviewsTitle}>
            <FormattedMessage id={title} />
        </h4>
        {content}
    </div>
)

const renderReviewSection = () =>
    renderPageSection('success.reviewSection.title', renderReviews(reviews))

const renderCarouselSection = (slides, imgs) => {
    const slidesConfig = slides.map(slide => ({
        ...slide,
        img: imgs[slide.imgName],
    }))

    return (
        <div className={style.carouselSection}>
            <Carousel slidesConfig={slidesConfig} />
        </div>
    )
}

const renderCards = (cards, imgs) => (
    <div className="row">
        {cards.map(card => (
            <SocialCard
                isFacebook={false}
                className={style.card}
                item={{
                    ...card,
                    img: imgs[card.imgName],
                }}
                key={card.text}
            />
        ))}
    </div>
)

const renderStoriesSection = (conf, imgs) => (
    <div className={cn('wrapper', style.wrapper)}>
        {conf.map(cardConf => {
            const cta = (
                <Button flex={false} size="medium">
                    <FormattedMessage id={cardConf.cta} />
                </Button>
            )
            const img = <Img fluid={imgs[cardConf.imgName]} />

            return (
                <ContentGrid
                    config={cardConf}
                    descriptionChildren={cta}
                    visualChildren={img}
                    key={cardConf.imgName}
                />
            )
        })}
    </div>
)

const renderCardsSection = (cards, imgs) =>
    renderPageSection('success.cardsSection.title', renderCards(cards, imgs))

export const Success = ({ data, setLayoutConfig }) => {
    const matches = useMediaQuery(mediaXsOnly)
    const footerImg = matches
        ? _.get(data, 'footerMobile.edges.0.node.childImageSharp.fluid')
        : _.get(data, 'footer.edges.0.node.childImageSharp.fluid')
    const cardImgs = generateOriginalNameImageMap(_.get(data, 'cards.edges'))

    useLayoutEffect(() => {
        setLayoutConfig({
            className: style.layout,
            textColorVariant: 'white',
        })
    }, [setLayoutConfig])

    return (
        <div>
            {renderHero()}
            {matches
                ? renderCarouselSection(config.topCards, cardImgs)
                : renderStoriesSection(config.topCards, cardImgs)}
            {renderCardsSection(config.cards, cardImgs)}
            {renderFooter(footerImg)}
            {renderReviewSection()}
        </div>
    )
}

Success.propTypes = {
    data: PropTypes.object.isRequired,
    setLayoutConfig: PropTypes.func.isRequired,
}

export default connect(null, { setLayoutConfig: setConfig })(Success)

export const query = graphql`
    {
        header: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/success/static/image/header.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        headerMobile: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/success/static/image/header_mobile.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footer: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/success/static/image/footer.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        footerMobile: allFile(
            filter: {
                relativePath: {
                    eq: "page/generic/success/static/image/footer_mobile.jpg"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 3840
                            srcSetBreakpoints: [1400, 1900, 2200, 3840]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        cards: allFile(
            filter: {
                relativePath: {
                    regex: "page/generic/success/static/image/card/"
                }
            }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(
                            maxWidth: 476
                            srcSetBreakpoints: [476]
                            toFormat: NO_CHANGE
                            quality: 90
                        ) {
                            originalName
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`
