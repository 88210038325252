export const topCards = [
    {
        title: 'success.topCard.1.title',
        subtitle: 'success.topCard.1.subtitle',
        text: 'success.topCard.1.content',
        cta: 'success.topCard.cta',
        imgName: 'anete.jpg',
        link: [{ href: 'success.topCard.1.link' }],
        className: 'center-xs end-sm middle-sm',
        isReversed: true,
    },
    {
        title: 'success.topCard.2.title',
        subtitle: 'success.topCard.2.subtitle',
        text: 'success.topCard.2.content',
        cta: 'success.topCard.cta',
        imgName: 'nicoles.jpg',
        link: [{ href: 'success.topCard.2.link' }],
        className: 'middle-xs start-sm center-xs',
    },
    {
        title: 'success.topCard.3.title',
        subtitle: 'success.topCard.3.subtitle',
        text: 'success.topCard.3.content',
        cta: 'success.topCard.cta',
        imgName: 'rosa.jpg',
        link: [{ href: 'success.topCard.3.link' }],
        className: 'center-xs end-sm middle-sm',
        isReversed: true,
    },
]

export const cards = [
    {
        text: 'success.card.1.title',
        imgName: 'christin.jpg',
        link: 'success.card.1.link',
    },
    {
        text: 'success.card.3.title',
        imgName: 'nicolas.jpg',
        link: 'success.card.3.link',
    },
    {
        text: 'success.card.4.title',
        imgName: 'nicole.jpg',
        link: 'success.card.4.link',
    },
    {
        text: 'success.card.5.title',
        imgName: 'verena.jpg',
        link: 'success.card.5.link',
    },
    {
        text: 'success.card.8.title',
        imgName: 'susann.jpg',
        link: 'success.card.8.link',
    },
    {
        text: 'success.card.2.title',
        imgName: 'dominic.jpg',
        link: 'success.card.2.link',
    },
]
